import React, { useEffect, useState } from 'react';
import './dialogdetailproduct.scss';
import data from '../../../data/products.json';

export const DialogDetailProduct = ({ indexCategory, indexProduct }) => {

    const [product, setProduct] = useState(null);

    useEffect(() => {
        // Verificar que la categoría y el producto existen antes de acceder a ellos
        if (data.categorias && data.categorias[indexCategory]) {
            const productInfo = data.categorias[indexCategory].productos[indexProduct];
            // Verificamos si existe el producto
            if (productInfo) {
                setProduct(productInfo);
            } else {
                console.error("Producto no encontrado en la categoría seleccionada.");
            }
        } else {
            console.error("Categoría no encontrada.");
        }
    }, [indexCategory, indexProduct]);

    return (
        <>
            {product != null ? (
                <div className="product-dialog-detail">

                    <div className="product-dialog-info">
                        <h1>{product.nombre}</h1>
                        <p>Reg. {product.registro}</p>

                        <div className="formula">
                            <h2>Fórmula:</h2>
                            {
                                (product.formula_aclaracion) && (
                                    <p>{product.formula_aclaracion}</p>
                                )
                            }
                          
                            {
                                product.formula.map((formula, index) => (
                                    <div 
                                    className={`formula-item ${(formula[1] !== undefined && formula[1] !== '') ? 'formula-item-slice' : ''}`}
                                        key={`formula-${index}`}>
                                        <span>{formula[0]}</span>
                                        <span>{formula[1]}</span>
                                    </div>
                                ))
                            }
                        </div>

                        <div className="dosis">
                            <h2>Dosis:</h2>
                            {Array.isArray(product.dosis) ? (
                                <>
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>Tamaño del animal</th>
                                                <th>Dosis</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {product.dosis.map((dosis, index) => (
                                                <tr key={`dosis-${index}`}>
                                                    <td>{dosis[0]}</td>
                                                    <td>{dosis[1]}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                    <p className="nota">*Dosis ajustable a criterio del Médico veterinario.</p>
                                </>
                            ) : (
                                <p>{product.dosis}</p>
                            )}
                        </div>

                        
                        {
                            product.indicaciones &&
                            (
                            <div className="indicaciones">
                                                        <h2>Indicaciones:</h2>
                                                        <p>{product.indicaciones}</p>
                                                        
                                                        {
                                                            product.indicaciones_aplicables?.map((indicacionesaplicables, index) => (
                                                                <li key={`indicacionesaplicables-${index}`}>{indicacionesaplicables}</li>
                                                            ))
                                                        }
                                                    </div>
                            )
                        }

                        

                        <div className="administracion">
                            <h2>Vía de administración:</h2>
                            <p>{product.via_administracion}</p>
                        </div>

                        <div className="presentacion">
                            <h2>Presentación:</h2>
                            <p>{product.presentacion}</p>
                        </div>

                        {/* <button className="add-to-bag">Add to bag</button> */}
                    </div>

                    <div className="product-dialog-image">
                        <img
                            src={process.env.PUBLIC_URL + product.imagenUrl}
                            alt="Product"
                            className="animate__animated animate__pulse"
                        />
                    </div>
                </div>
            ) : <p>Producto no disponible.</p>}
        </>
    );

};
